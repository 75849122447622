<template>
  <b-card :text-variant="type">
    <b-card-header>
      <b-card-title v-if="title">{{ title }}</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-card-text v-if="message && !loading">{{ message }}</b-card-text>
      <b-spinner v-if="loading" />
    </b-card-body>
    <b-card-footer v-if="!loading">
      <b-row>
        <b-col>
          <b-button v-if="paymentLink" variant="success" @click="onPayHandler">
            Pay
          </b-button>
        </b-col>
        <b-col>
          <count-down-button
            title="Ok"
            @zero="redirect()"
            @click="redirect()"
          />
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardFooter,
  BCardBody,
  BButton,
  BCardText,
  BCardTitle,
  BCardHeader,
  BSpinner,
} from "bootstrap-vue";
import { checkSubscription } from "@/services/api/cart";
import { apiErrorHandler } from "@/services/utils/util";
import store from "@/store";
import CountDownButton from "@/layouts/components/buttons/CountDownButton";
import { mapActions } from "vuex";

export default {
  components: {
    CountDownButton,
    BRow,
    BCol,
    BCard,
    BCardFooter,
    BCardBody,
    BButton,
    BCardText,
    BCardTitle,
    BCardHeader,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      type: "success",
      title: "Subscription",
      message: "",
      paymentLink: null,
    };
  },
  mounted() {
    this.checkSubscription().catch(console.error);
  },
  computed: {
    isAuthorized() {
      return !!store.getters["auth/authUser"];
    },
  },
  methods: {
    ...mapActions({
      refreshProfile: "auth/refreshProfile",
      fetchCart: "channelSelector/fetchCart",
    }),
    redirect() {
      this.$router.replace({
        name: this.isAuthorized ? "profile" : "channels",
      });
    },
    onPayHandler() {
      if (!this.paymentLink) {
        return;
      }
      window.location.href = this.paymentLink;
    },
    async checkSubscription() {
      this.loading = true;
      try {
        await checkSubscription(this.$route.query);
        this.title = "Subscription";
        this.message = `Subscription successfully [${this.$route.query.subscription_id}]`;
        await this.refreshProfile();
        await this.fetchCart();
      } catch (error) {
        const status = error?.response?.status;
        const data = error?.response?.data;
        if (status === 402) {
          const {
            message,
            data: { paymentLink },
          } = data;
          this.type = "danger";
          this.message = message;
          this.paymentLink = paymentLink;
        } else {
          apiErrorHandler(error);
          await this.$router.replace("/");
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
